h1 {
  @apply text-4xl font-bold text-white;
  padding-bottom: 10px;
  padding-top: 10px;
}

h2 {
  @apply text-2xl font-semibold text-white;
  padding-bottom: 10px;
  padding-top: 10px;
}

h3 {
  @apply text-xl font-semibold text-white;
  padding-bottom: 10px;
  padding-top: 10px;
}

h4 {
  @apply text-lg font-semibold text-white;
  padding-bottom: 10px;
  padding-top: 10px;
}

h5 {
  @apply text-lg font-semibold text-white;
  padding-bottom: 10px;
  padding-top: 10px;
}

h6 {
  @apply text-lg font-semibold text-white;
  padding-bottom: 10px;
  padding-top: 10px;
}

p {
  @apply text-white leading-6;
}

ul,
ol {
  @apply list-disc pl-5;
  @apply text-white;
  @apply pt-2 pb-2 pr-2;
}

a {
  @apply text-blue-500 hover:underline;
}

img {
  @apply max-w-full h-auto;
}

code {
  background-color: #141414;
  color: white;
  padding: 4px;
  border-left: 2px solid;
  border-color: #084aff;
}
